import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { Script } from "gatsby";

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";

import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import About from "./src/pages/about";
import Cart from "./src/pages/cart";
import DeliveryAndReturns from "./src/pages/delivery-returns-information";
import Events from "./src/pages/events";
import Index from "./src/pages/index";
import MailingList from "./src/pages/mailing-list";
import Shop from "./src/pages/shop";
import TermsAndConditions from "./src/pages/terms-and-conditions";

// Templates
import Event from "./src/templates/event";
import Product from "./src/templates/product";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new createHttpLink({
    uri: `https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.SHOPIFY_ACCESS_TOKEN,
      Accept: "application/graphql",
    },
    fetch,
  }),
});

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: About,
          cart: Cart,
          delivery_and_returns: DeliveryAndReturns,
          events: Events,
          homepage: Index,
          mailing_list: MailingList,
          shop: Shop,
          terms_and_conditions: TermsAndConditions,
          event: Event,
          product: Product,
        }),
      },
    ]}
  >
    <ApolloProvider client={apolloClient}>
      <StoreProvider>{element}</StoreProvider>
    </ApolloProvider>
  </PrismicPreviewProvider>
);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script>
        {`var s = document.createElement("script");
        s.setAttribute("data-account", "FQ5APu0KJp");
        s.setAttribute("src", "https://cdn.userway.org/widget.js");
        (document.body || ddocument.head).appendChild(s);`}
      </Script>
    </>
  );
};
