import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useDebounce } from "react-use";

// SEO
// import { PageSeo } from "../components/seo/seo";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-context";

// Components
import { LineItem } from "../components/product/line-item";

// Utils
import { formatPrice } from "../components/utils/format-price";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 75px 50px 0 50px;

  @media (max-width: 960px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  color: #0b646f;

  min-height: calc(${props => props.height}px - 50px);

  & .breadcrumbs {
    flex: 100%;
    width: 100%;

    & p {
      font-size: 12px;
      line-height: 28px;

      color: #0b646f;

      margin: 0 0 1em 0;
    }

    @media (max-width: 768px) {
      padding: 0 10px;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
  }

  & .empty-cart {
    flex: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    min-height: calc(${props => props.height}px - 75px - 40px - 40px - 40px);

    & p {
      font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
        "Lucida Grande", sans-serif;
      /* font-size: 22px;
      line-height: 32px; */

      font-size: 18px;
      line-height: 26px;
      text-align: center;

      max-width: 440px;
      margin: 0 auto;

      & a {
        color: #debb9b;
      }

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    & a {
      display: block;

      height: 40px;
      width: 100%;

      font-size: 15px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.02em;

      background-color: #f5eadf;

      transition: 250ms color ease, 250ms background-color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }
    }

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      & a {
        height: 60px;

        font-size: 14px;
        line-height: 59px;
      }
    }
  }
`;

const Table = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 50px;

  & .outer-column {
    &.left {
      grid-column: 1 / 8;
      border-top: 1px solid #f5eadf;

      @media (max-width: 1200px) {
        grid-column: 1 / 9;
      }
      @media (max-width: 768px) {
        border-top: 0;
      }
    }
    &.right {
      grid-column: 9 / 13;
    }
  }

  & .item {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 50px;

    padding: 15px 0;
    border-bottom: 1px solid #f5eadf;

    & .item-image {
      grid-column: 1 / 3;
    }

    & .item-details {
      grid-column: 3 / 7;

      & h1,
      & p {
        font-size: 15px;
        line-height: 20px;

        margin: 0;
      }

      & .item-style {
        font-size: 12px;
        line-height: 20px;

        @media (max-width: 768px) {
          font-size: 8px;
          line-height: 11px;
        }
      }

      & .item-quantity {
        display: grid;
        grid-template-columns: 105px auto;
        align-items: baseline;

        margin: 20px 0 25px 0;

        @media (max-width: 768px) {
          margin: 10px 0;
          align-items: center;
        }

        & .quantity-title {
          & p {
            font-size: 12px;
            line-height: 20px;
          }
        }

        & .quantity-buttons-container {
          & button {
            padding: 0 10px;

            @media (hover: hover) and (pointer: fine) {
              &:hover,
              &:focus,
              &:focus-within {
                & svg {
                  & path {
                    transition: 250ms fill ease;
                    fill: #e9ccb2;
                  }
                }
              }
            }

            @media (max-width: 768px) {
              & svg {
                height: 12px;
                width: auto;
              }
            }
          }

          & .quantity {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }

      & .item-remove {
        & button {
          font-size: 12px;
          line-height: 29px;

          color: #0b646f;
          transition: 250ms background-color ease, 250ms color ease;
          letter-spacing: 0.02em;

          text-align: center;

          background: #f5eadf;
          height: 30px;
          width: 120px;

          @media (hover: hover) and (pointer: fine) {
            &:hover,
            &:focus,
            &:focus-within {
              color: #fff;
              background-color: #0b646f;
            }
          }

          @media (max-width: 768px) {
            line-height: 24px;

            height: 25px;
            width: 95px;
          }
        }
      }
    }
  }

  & .totals {
    width: 100%;
    max-width: 400px;

    & .title {
      margin: 0 0 3em 0;

      & h1 {
        font-size: 15px;
        line-height: 20px;
      }
    }

    & .container {
      & .note-to-seller {
        margin: 3em 0 0 0;

        & label {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          display: block;
          margin: 0 0 20px 0;
        }

        & textarea {
          padding: 10px;
          border: 1px solid #f5eadf;
          width: calc(100% - 10px - 10px);

          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.02em;
          font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0b646f;
        }
      }

      & .support-message {
        margin: 50px 0;

        & p {
          font-size: 12px;
          line-height: 18px;

          text-align: center;
          margin: 0;
        }
      }

      & .inner-grid-container {
        display: grid;
        grid-template-columns: 100px auto;
        grid-column-gap: 15px;

        & .column {
          &.left {
            & h3 {
              font-size: 12px;
              line-height: 20px;
            }
          }

          &.right {
            & p {
              font-size: 15px;
              line-height: 20px;
              letter-spacing: 0.02em;

              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .outer-column {
      &.right {
        grid-column: unset;

        & .totals {
          padding: 15px 0;

          & .title {
            margin: 0 0 1em 0;
          }

          & .container {
            & .inner-grid-container {
              grid-template-columns: 80px auto;
            }

            & .note-to-seller {
              margin: 1em 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .outer-column {
      &.right {
        & .totals {
          padding: 15px 20px;
          max-width: 100%;

          & .title {
            & h1 {
              font-size: 14px;
              line-height: 17px;
            }
          }

          & .container {
            & .inner-grid-container {
              & .column {
                &.left {
                  & h3 {
                    font-size: 11px;
                    line-height: 17px;
                  }
                }

                &.right {
                  & p {
                    font-size: 12px;
                    line-height: 17px;
                  }
                }
              }
            }

            & .note-to-seller {
              & label {
                font-size: 11px;
                line-height: 17px;
              }
            }
          }
        }
      }
    }

    & .item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;

      padding: 15px 20px;

      & .item-image {
        grid-column: 1;
      }

      & .item-details {
        grid-column: 2;

        & h1,
        & p,
        & button {
          font-size: 8px;
          line-height: 11px;
        }

        & .item-quantity {
          & .quantity-title p {
            font-size: 8px;
            line-height: 11px;
          }

          & .quantity-buttons-container {
            display: flex;
            align-items: center;
            flex-direction: row;

            & button,
            & .quantity {
              font-size: 11px;
            }
          }
        }

        & .item-remove {
          & button {
            font-size: 8px;
            line-height: 11px;
          }
        }
      }
    }
  }
`;

const Cart = () => {
  const { checkout, client } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const height = use100vh();

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  const [textAreaValue, setTextAreaValue] = useState(``);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(textAreaValue);
    },
    500,
    [textAreaValue]
  );

  useEffect(() => {
    setPageSetup({
      pageTitle: `cart`,
      pageColor: `#DDE1D4`,
      pageType: `cart`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  useEffect(() => {
    if (debouncedValue.length >= 1) {
      // Add in cart note
      const input = { note: debouncedValue };

      client.checkout.updateAttributes(checkout.id, input).then(checkout => {
        // Do something with the updated checkout
      });
    }
  }, [debouncedValue]);

  let checkoutUrl =
    checkout?.webUrl !== undefined
      ? checkout?.webUrl.replace(
          "houseofvoltaire.org/cart/c/",
          "houseofvoltaire.myshopify.com/checkouts/cn/"
        )
      : checkout?.webUrl;

  return (
    <>
      <PageSeo seoTitle={`Cart`} seoText={null} seoImage={null} />

      <Page height={height}>
        <div className="page-container">
          <div className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link> → Bag
            </p>
          </div>

          {emptyCart ? (
            <div className="empty-cart">
              <p>
                Your cart is currently empty. <br /> Return to the{" "}
                <Link to={`/shop/`}>shop</Link>
              </p>
            </div>
          ) : (
            <>
              <Table>
                <div className="outer-column left">
                  {checkout.lineItems.map(item => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </div>

                <div className="outer-column right">
                  <div className="row totals">
                    <div className="title">
                      <h1>Bag Total</h1>
                    </div>

                    <div className="container">
                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3>Subtotal</h3>
                        </div>
                        <div className="column right">
                          <p className="news-gothic">
                            {formatPrice(
                              checkout.subtotalPriceV2.currencyCode,
                              checkout.subtotalPriceV2.amount
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3>Delivery</h3>
                        </div>
                        <div className="column right">
                          <p className="news-gothic">calculated at checkout</p>
                        </div>
                      </div>

                      <div className="line note-to-seller">
                        <label className="uppercase">Note To Seller</label>
                        <textarea
                          name="note"
                          rows="10"
                          value={textAreaValue}
                          onChange={({ currentTarget }) => {
                            setTextAreaValue(currentTarget.value);
                          }}
                        />
                      </div>

                      <div className="support-message">
                        <p className="uppercase">
                          All sales support Studio Voltaire’s world-renowned
                          artistic and public programmes
                        </p>
                      </div>

                      <div className="buttons-container">
                        <a href={checkoutUrl}>Checkout</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Table>
            </>
          )}
        </div>
      </Page>
    </>
  );
};

export default Cart;
