import React, { useContext, useEffect, useState, useCallback } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { isEqual, kebabCase } from "lodash";
import { useAsyncFn } from "react-use";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-context";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { AddToCart } from "../components/product/add-to-cart";
import { VariantSelectors } from "../components/product/variant-selectors";
import { RelatedProducts } from "../components/product/related-products";
import { Lightbox } from "../components/images/lightbox-gallery";
import { Gallery } from "../components/images/gallery";
import { ProductArtistDesigner } from "../components/product/product-artist-designer";
// import { StudioVoltaireArchive } from "../components/product/studio-voltaire-archive";
import { QuantityDropdown } from "../components/product/quantity-dropdown";

// Forms
import { ProductEnquiryForm } from "../components/forms/product-enquiry-form";

// Utils
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

import { getMetafield } from "../components/utils/get-metafield";
import { formatPrice } from "../components/utils/format-price";

// SEO
import { PageSeo } from "../components/seo/page-seo";
import { use100vh } from "react-div-100vh";

const Page = styled.div`
  margin: 75px 0 0 0;
  color: #0b646f;

  @media (max-width: 960px) {
    margin: 30px 0 40px 0;
  }

  @media (max-width: 768px) {
    margin: 30px 0 59px 0;
  }

  & .product-container,
  & .remaining-product-images-container {
    padding: 0 50px;

    @media (max-width: 960px) {
      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }

  & .product-container {
    margin: 0 0 70px 0;

    @media (max-width: 768px) {
      margin: 0 0 15px 0;
    }
  }

  & .breadcrumbs {
    & p {
      font-size: 12px;
      line-height: 28px;

      margin: 0 0 1em 0;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin: 0;

      & p {
        font-size: 8px;
        line-height: 28px;
      }
    }
  }

  & .single-image-container {
    cursor: pointer;

    & .gatsby-image-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    & img {
      object-fit: contain !important;
    }
  }

  & .price-on-enquiry-button {
    color: #0b646f;
    transition: 250ms color ease;

    &:hover {
      color: #e9ccb2;
    }
  }

  & .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 70px;

    @media (max-width: 960px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .left-column {
      grid-column: 1 / 7;

      & .single-media-container {
        margin: 0;

        cursor: pointer;
      }

      & .aspect-ratio-image-container {
        & .content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          & .single-media-container {
            width: 100%;
            height: auto;
          }
        }
      }

      @media (max-width: 960px) {
        grid-column: unset;
      }
    }

    & .right-column {
      grid-column: 7 / 13;

      @media (max-width: 960px) {
        grid-column: unset;
        padding: 15px 10px;
        /* padding: 15px 10px 0 10px; */
      }

      &.grid {
        display: grid;
        grid-auto-rows: calc(${props => props.height}px - 155px - 22px) auto;
        /* grid-auto-rows: 20px auto 20px; */
        grid-row-gap: 20px;

        @media (max-width: 1100px) {
          grid-auto-rows: auto auto;
        }

        & .spacer {
          display: none;
        }

        @media (max-width: 960px) {
          display: block;
          grid-auto-rows: unset;
          grid-row-gap: unset;
        }

        & .text-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          margin: 0 auto;

          @media (max-width: 960px) {
            display: block;
            flex-direction: unset;
            align-items: unset;
            justify-content: unset;

            margin: 0;
          }
        }
      }

      & .text-container {
        max-width: 420px;

        @media (max-width: 960px) {
          max-width: unset;
        }

        & .product-title {
          margin: 0 0 50px 0;

          & h1,
          & h2,
          & p {
            /* font-size: 22px; */
            /* line-height: 28px; */
            font-size: 18px;
            line-height: 22px;

            margin: 0;

            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
              text-align: center;
            }
          }
        }

        & .price-container {
          &.sale-price {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & p {
              &.compare-at-price {
                text-decoration: line-through;
                margin: 0 20px 0 0;
              }
            }
          }
        }

        & .product-description {
          margin: 0 0 15px 0;

          & p {
            /* font-size: 15px !important; */
            /* line-height: 20px !important; */

            font-size: 13px !important;
            line-height: 17px !important;

            letter-spacing: 0.02em !important;
            margin: 1em 0 !important;

            @media (max-width: 768px) {
              font-size: 12px !important;
              line-height: 17px !important;
            }

            & span {
              font-size: 13px !important;
              line-height: 17px !important;

              letter-spacing: 0.02em !important;
              margin: 1em 0 !important;

              @media (max-width: 768px) {
                font-size: 12px !important;
                line-height: 17px !important;
              }
            }
          }
        }

        & .product-details-container {
          margin: 0 0 20px 0;

          & .line {
            display: grid;
            grid-template-columns: 100px auto;
            grid-column-gap: 25px;

            & p {
              margin: 0;

              &.title {
                font-size: 11px;
                line-height: 17px;

                /* font-size: 12px;
                line-height: 20px; */

                @media (max-width: 768px) {
                  font-size: 11px;
                  line-height: 17px;
                }
              }

              &.text {
                font-size: 13px;
                line-height: 17px;

                /* font-size: 15px;
                line-height: 20px; */
                letter-spacing: 0.02em;

                @media (max-width: 768px) {
                  font-size: 12px;
                  line-height: 17px;
                }
              }
            }
          }
        }

        & .additional-product-text-container {
          margin: 20px 0 45px 0;
        }

        & .product-options-container {
          margin: 45px 0 50px 0;

          @media (max-width: 768px) {
            margin: 20px 0;
          }
        }

        & .product-buttons {
          max-width: 400px;

          & button {
            /* font-size: 15px; */
            font-size: 12px;
            line-height: 39px;
            letter-spacing: 0.02em;
            text-align: center;

            background-color: #f5eadf;
            color: #0b646f;

            width: 100%;
            height: 40px;

            @media (hover: hover) and (pointer: fine) {
              transition: 250ms color ease, 250ms background-color ease;

              &:hover {
                color: #fff;
                background-color: #0b646f;
              }
            }

            &:disabled {
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  color: #0b646f;
                  background-color: #f5eadf;
                }
              }
            }
          }

          @media (max-width: 768px) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;

            z-index: 10;

            max-width: 100%;

            & button {
              font-size: 14px;

              height: 60px;
              line-height: 59px;
            }
          }
        }
      }

      & .product-information-container {
        max-width: 420px;
        margin: 0 auto;

        & .inner-information-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: baseline;

          position: sticky;
          top: calc(${props => props.height}px - 42px);

          & a,
          & button {
            display: block;

            /* font-size: 12px;
            line-height: 20px; */

            font-size: 11px;
            line-height: 17px;

            margin: 0 20px 0 0;

            color: #0b646f;

            transition: 250ms color ease;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #e9ccb2;
              }
            }

            @media (max-width: 768px) {
              font-size: 11px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }

  & .remaining-product-images-container {
    margin: 0 0 70px 0;

    & .single-media-container {
      grid-column: span 6;
    }
  }
`;

const Product = ({ data }) => {
  const product = data.shopifyProduct;

  const { options, variants } = product;
  const { client } = useContext(StoreContext);
  const height = use100vh();

  const [fetchedProductStatus, setFetchedProductStatus] = useState({
    status: `active`,
  });

  const findFirstAvailableVariant = variants.find(
    ({ availableForSale }) => availableForSale === true
  );

  const initialVariant =
    findFirstAvailableVariant !== undefined
      ? findFirstAvailableVariant
      : variants[0];

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.shopifyId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.shopifyId, client.product]
  );

  const [hasVariantChanged, setHasVariantChanged] = useState(false);
  const handleOptionChange = (index, value) => {
    setHasVariantChanged(true);

    if (value === "") {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant, checkAvailablity, product.storefrontId]);

  const hasVariants = variants.length > 1;

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 961px)");

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `product`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  // Product Enquiry Form
  const [isProductEnquiryFormOpen, setIsProductEnquiryFormOpen] = useState(
    false
  );

  // Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);

  const [productStatus, getProductStatus] = useAsyncFn(async id => {
    const response = await fetch(`/.netlify/functions/get-product-status`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();
    setFetchedProductStatus(result);
    return result;
  }, []);

  useEffect(() => {
    getProductStatus(product.shopifyId);
  }, []);

  const media = data.shopifyProduct.media.map((media, index) => {
    if (media.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}`}
          className={`single-image-container single-media-container`}
          onClick={() => {
            if (isDesktop) {
              setIsLightboxOpen(true);
              setActiveSlideNumber(index);
            }
          }}
        >
          {media.image !== null && (
            <>
              {media.image.width > media.image.height ? (
                <>
                  {index === 0 ? (
                    <AspectRatioImageContainer image={null} padding={125}>
                      <GatsbyImage
                        image={media.image.gatsbyImageData}
                        alt={media.alt !== null ? media.alt : ""}
                        draggable="false"
                        loading="lazy"
                      />
                    </AspectRatioImageContainer>
                  ) : (
                    <GatsbyImage
                      image={media.image.gatsbyImageData}
                      alt={media.alt !== null ? media.alt : ""}
                      draggable="false"
                      loading="lazy"
                    />
                  )}
                </>
              ) : (
                <AspectRatioImageContainer image={null} padding={125}>
                  <GatsbyImage
                    image={media.image.gatsbyImageData}
                    alt={media.alt !== null ? media.alt : ""}
                    draggable="false"
                    loading="lazy"
                  />
                </AspectRatioImageContainer>
              )}
            </>
          )}
        </div>
      );
    }

    if (media.mediaContentType === "VIDEO") {
      const sources = media.sources.map((source, index) => (
        <source
          src={source.url}
          type={`video/${source.format}`}
          key={`single_video_${index}`}
        />
      ));
      return (
        <div
          key={`single_product_video_container_${index}`}
          className={`single-video-container single-media-container`}
          onClick={() => {
            if (isDesktop) {
              setIsLightboxOpen(true);
              setActiveSlideNumber(index);
            }
          }}
        >
          <video autoPlay muted loop playsInline>
            {sources}
          </video>
        </div>
      );
    }
  });

  const firstMediaItem = media.filter((item, index) => index === 0);
  const remainingMediaItems = media.filter(
    (item, index) => index >= 1 && index <= 2
  );

  const artistDesignerPageId = getMetafield(
    data.shopifyProduct,
    "artist_designer"
  );

  const finishingText = getMetafield(data.shopifyProduct, "finishing");
  const editionSizeText = getMetafield(data.shopifyProduct, "edition_size");
  const dimensionsText = getMetafield(data.shopifyProduct, "dimensions");
  const additionalProductText = getMetafield(
    data.shopifyProduct,
    "additional_product_text"
  );
  const aboutTheArtworkText = getMetafield(
    data.shopifyProduct,
    "about_the_artwork"
  );

  const isProductPriceOnEnquiry = getMetafield(
    data.shopifyProduct,
    "price_on_enquiry"
  );

  const isProductOnePerCustomer = getMetafield(
    data.shopifyProduct,
    "purchase_limit"
  );

  const [productArtistDesigner, getArtistDesigner] = useAsyncFn(async id => {
    const response = await fetch(
      `/.netlify/functions/get-artist-designer-details`,
      {
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    );

    const result = await response.json();
    return result;
  }, []);

  return (
    <>
      <PageSeo
        seoTitle={data.shopifyProduct.seo.title}
        seoText={
          data.shopifyProduct.seo.description !== null
            ? data.shopifyProduct.seo.description
            : data.shopifyProduct.description
        }
        seoImage={
          data.shopifyProduct.featuredImage !== null &&
          data.shopifyProduct.featuredImage.originalSrc !== null
            ? data.shopifyProduct.featuredImage.originalSrc
            : null
        }
      />
      <Page height={height}>
        <section
          className="product-container"
          itemScope
          itemType="http://schema.org/Product"
        >
          <div className="breadcrumbs">
            <p className="uppercase">
              <Link to={`/`}>HOME</Link> → <Link to={`/shop/`}>SHOP</Link>
              {data.shopifyProduct.collections.length >= 1 && (
                <>
                  {" "}
                  →{" "}
                  <Link
                    to={`/collections/${data.shopifyProduct.collections[0].handle}/`}
                  >
                    {data.shopifyProduct.collections[0].title}
                  </Link>
                </>
              )}{" "}
              {getMetafield(data.shopifyProduct, "artist_name") !== null && (
                <Link
                  to={`/${kebabCase(
                    getMetafield(data.shopifyProduct, "artist_name")
                  )}/`}
                >
                  → {getMetafield(data.shopifyProduct, "artist_name")}
                </Link>
              )}
            </p>
          </div>

          <div className="grid-12">
            <div className="left-column">
              {isDesktop ? (
                <>
                  {hasVariantChanged && productVariant.image !== null ? (
                    <AspectRatioImageContainer image={null} padding={125}>
                      <div
                        className={`single-image-container single-media-container`}
                      >
                        <GatsbyImage
                          image={productVariant.image.gatsbyImageData}
                          alt={productVariant.title}
                          draggable="false"
                          loading="lazy"
                        />
                      </div>
                    </AspectRatioImageContainer>
                  ) : (
                    <>{firstMediaItem}</>
                  )}
                </>
              ) : (
                <Gallery media={media} autoplay={false} />
              )}
            </div>

            <div className="right-column grid">
              <div className="spacer" />

              <div className="text-container">
                <div className="inner-text-container">
                  <div className="product-title">
                    <h2>{getMetafield(data.shopifyProduct, "artist_name")}</h2>
                    <h1 itemProp="name">{data.shopifyProduct.title}</h1>

                    {(isProductPriceOnEnquiry === true ||
                      isProductPriceOnEnquiry === "true") && (
                      <div className={`price-container`}>
                        <p className="price">Price on enquiry</p>
                      </div>
                    )}

                    {isProductPriceOnEnquiry !== true &&
                      isProductPriceOnEnquiry !== "true" && (
                        <div
                          itemProp="offers"
                          itemScope
                          itemType="http://schema.org/Offer"
                          className={`price-container ${
                            productVariant.compareAtPrice !== null
                              ? `sale-price`
                              : ``
                          }`}
                        >
                          <meta itemProp="priceCurrency" content="GBP" />
                          <meta
                            itemProp="price"
                            content={productVariant.price}
                          />
                          <link
                            itemProp="availability"
                            href={
                              available
                                ? "http://schema.org/InStock"
                                : "http://schema.org/OutOfStock"
                            }
                          />

                          {productVariant.compareAtPrice !== null && (
                            <p className="compare-at-price">
                              {formatPrice(
                                "GBP",
                                productVariant.compareAtPrice
                              )}
                            </p>
                          )}
                          <p className="price">
                            {formatPrice("GBP", productVariant.price)}
                          </p>
                        </div>
                      )}
                  </div>

                  <div
                    itemProp="description"
                    className="product-description news-gothic"
                    dangerouslySetInnerHTML={{
                      __html: data.shopifyProduct.descriptionHtml,
                    }}
                  />

                  {(editionSizeText !== null ||
                    dimensionsText !== null ||
                    finishingText !== null) && (
                    <div className="product-details-container">
                      {editionSizeText !== null && (
                        <div className="line">
                          <p className="title">Edition Size</p>
                          <p className="text news-gothic">{editionSizeText}</p>
                        </div>
                      )}

                      {dimensionsText !== null && (
                        <div className="line">
                          <p className="title">Dimensions</p>
                          <p className="text news-gothic">{dimensionsText}</p>
                        </div>
                      )}

                      {finishingText !== null && (
                        <div className="line">
                          <p className="title">Finishing</p>
                          <p className="text news-gothic">{finishingText}</p>
                        </div>
                      )}
                    </div>
                  )}

                  {additionalProductText !== null && (
                    <div className="additional-product-text-container">
                      <p className="news-gothic">{additionalProductText}</p>
                    </div>
                  )}

                  {isProductPriceOnEnquiry !== true &&
                    isProductPriceOnEnquiry !== "true" && (
                      <div className="product-options-container">
                        {hasVariants &&
                          options.map(({ id, name, values }, index) => (
                            <div key={`single_variant_${id}_${index}`}>
                              <VariantSelectors
                                handleOptionChange={handleOptionChange}
                                items={values}
                                name={name}
                                index={index}
                              />
                            </div>
                          ))}

                        <QuantityDropdown
                          inventoryQuantity={productVariant.inventoryQuantity}
                          setQuantity={setQuantity}
                        />
                      </div>
                    )}

                  {isProductPriceOnEnquiry !== true &&
                    isProductPriceOnEnquiry !== "true" && (
                      <AddToCart
                        variantId={productVariant.storefrontId}
                        isPreview={false}
                        quantity={quantity}
                        available={available}
                        product={product}
                        price={formatPrice("GBP", productVariant.price)}
                        artistName={getMetafield(
                          data.shopifyProduct,
                          "artist_name"
                        )}
                        isOnePerCustomer={
                          isProductOnePerCustomer === true ||
                          isProductOnePerCustomer === "true"
                        }
                      />
                    )}

                  {(isProductPriceOnEnquiry === true ||
                    isProductPriceOnEnquiry === "true") && (
                    <button
                      className="price-on-enquiry-button uppercase"
                      type="button"
                      onClick={() => setIsProductEnquiryFormOpen(true)}
                    >
                      → Enquire About This Artwork
                    </button>
                  )}
                </div>
              </div>

              <div className="product-information-container">
                <div className="inner-information-container">
                  <button
                    type="button"
                    className="uppercase"
                    onClick={() => setIsProductEnquiryFormOpen(true)}
                    style={{
                      visibility:
                        isProductPriceOnEnquiry === true ||
                        isProductPriceOnEnquiry === "true"
                          ? `hidden`
                          : `visible`,
                    }}
                  >
                    → Enquire About This Artwork
                  </button>

                  <Link
                    className="uppercase"
                    to={`/delivery-returns-information/`}
                    state={{ from: `website` }}
                  >
                    → Delivery And Returns Information
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div itemProp="brand" itemScope itemType="http://schema.org/Brand">
            <meta
              itemProp="name"
              content={getMetafield(data.shopifyProduct, "artist_name")}
            />
          </div>
          {data.shopifyProduct.featuredImage && (
            <meta
              itemProp="image"
              content={data.shopifyProduct.featuredImage.originalSrc}
            />
          )}
          <meta itemProp="sku" content={productVariant.sku} />
          <link
            itemProp="url"
            href={`https://houseofvoltaire.org/products/${data.shopifyProduct.handle}`}
          />

          {/* Add this new Offers section */}
          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="priceCurrency" content="GBP" />
            <meta itemProp="price" content={productVariant.price} />
            <meta
              itemProp="itemCondition"
              content="http://schema.org/NewCondition"
            />
            <link
              itemProp="availability"
              href={
                available
                  ? "http://schema.org/InStock"
                  : "http://schema.org/OutOfStock"
              }
            />
            <meta
              itemProp="priceValidUntil"
              content={
                new Date(Date.now() + 86400000).toISOString().split("T")[0]
              }
            />
            <link
              itemProp="url"
              href={`https://houseofvoltaire.org/products/${data.shopifyProduct.handle}`}
            />
          </div>

          {/* Add this new AggregateRating section */}
          <div
            itemProp="aggregateRating"
            itemScope
            itemType="http://schema.org/AggregateRating"
          >
            <meta itemProp="ratingValue" content="5" />
            <meta itemProp="reviewCount" content="1" />
          </div>

          {/* Add this new Review section */}
          <div itemProp="review" itemScope itemType="http://schema.org/Review">
            <div
              itemProp="author"
              itemScope
              itemType="http://schema.org/Person"
            >
              <meta itemProp="name" content="House of Voltaire" />
            </div>
            <div
              itemProp="reviewRating"
              itemScope
              itemType="http://schema.org/Rating"
            >
              <meta itemProp="ratingValue" content="5" />
              <meta itemProp="bestRating" content="5" />
            </div>
          </div>
        </section>
        {isDesktop && (
          <section className="remaining-product-images-container">
            <div className="grid-12">{remainingMediaItems}</div>
          </section>
        )}
        {artistDesignerPageId !== null && (
          <section className="artist-designer-container">
            <ProductArtistDesigner
              id={artistDesignerPageId}
              artistName={getMetafield(data.shopifyProduct, "artist_name")}
              aboutTheArtworkText={aboutTheArtworkText}
              productArtistDesigner={productArtistDesigner}
              getArtistDesigner={getArtistDesigner}
            />
          </section>
        )}

        {data.allShopifyProduct.nodes.length >= 1 && (
          <RelatedProducts
            products={data.allShopifyProduct.nodes}
            product={data.shopifyProduct}
            isDesktop={isDesktop}
          />
        )}
        {/* {productArtistDesigner !== null && productArtistDesigner.length >= 1 && (
          <section className="studio-voltaire-archive">
            <StudioVoltaireArchive
              id={artistDesignerPageId}
              productArtistDesigner={productArtistDesigner}
              getArtistDesigner={getArtistDesigner}
            />
          </section>
        )} */}
      </Page>

      {isLightboxOpen && (
        <Lightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          productMedia={data.shopifyProduct.media}
          activeSlideNumber={activeSlideNumber}
        />
      )}
      {isProductEnquiryFormOpen && (
        <ProductEnquiryForm
          isProductEnquiryFormOpen={isProductEnquiryFormOpen}
          setIsProductEnquiryFormOpen={setIsProductEnquiryFormOpen}
          artist={getMetafield(data.shopifyProduct, "artist_name")}
          title={data.shopifyProduct.title}
          url={`https://houseofvoltaire.org/products/${data.shopifyProduct.handle}`}
          image={data.shopifyProduct.featuredImage.gatsbyImageData}
        />
      )}

      <form
        name="product-enquiry"
        data-netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input name="url" type="hidden" />
        <input name="bot-field" type="hidden" />
        <input name="subject" type="hidden" />
        <input name="firstName" type="hidden" />
        <input name="lastName" type="hidden" />
        <input name="email" type="hidden" />
        <textarea name="message" type="hidden" />
        <input name="privacyPolicyAgreed" type="hidden" />
        <input name="url" type="hidden" />
      </form>
    </>
  );
};

export default Product;

export const query = graphql`
  query ProductQuery($id: String, $tags: [String!]) {
    shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      description
      productType
      tags
      handle
      shopifyId
      featuredImage {
        gatsbyImageData
        originalSrc
      }
      collections {
        title
        handle
      }
      seo {
        description
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      shopifyId
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        storefrontId
        inventoryQuantity
        compareAtPrice
        inventoryPolicy
        selectedOptions {
          name
          value
        }
        image {
          gatsbyImageData(aspectRatio: 0.8, layout: FULL_WIDTH)
        }
      }
      metafields {
        value
        key
      }
      options {
        name
        values
        shopifyId
      }
      media {
        alt
        ... on ShopifyVideo {
          id
          sources {
            url
            width
            height
            format
          }
          mediaContentType
        }
        ... on ShopifyMediaImage {
          id
          image {
            width
            height
            gatsbyImageData(width: 700, layout: FULL_WIDTH)
          }
          mediaContentType
        }
        ... on ShopifyExternalVideo {
          id
          embeddedUrl
          mediaContentType
        }
      }
    }
    allShopifyProduct(
      filter: { status: { eq: ACTIVE }, tags: { in: $tags }, id: { ne: $id } }
    ) {
      nodes {
        title
        descriptionHtml
        description
        productType
        tags
        handle
        shopifyId
        featuredImage {
          gatsbyImageData(width: 700)
        }
        mobileImage: featuredImage {
          gatsbyImageData(width: 300)
        }
        collections {
          title
          handle
        }
        seo {
          description
          title
        }
        totalInventory
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          compareAtPrice
          inventoryPolicy
          selectedOptions {
            name
            value
          }
        }
        metafields {
          value
          key
        }
        options {
          name
          values
          shopifyId
        }
      }
    }
  }
`;
